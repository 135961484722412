<template>
  <div>
    <vx-card
      no-shadow
      card-border
      class="mb-4">
      <vue-element-loading
        :active="!selectedService"
        color="rgba(var(--vs-primary), 1)"
        spinner="mini-spinner"/>

      <div class="vx-row">
        <div class="top-up-service-select vx-col w-full md:w-1/2 flex items-center md:pr-0">
          <p class="whitespace-no-wrap mr-3">
            {{ $t('PricingBasedOnSending') }}
          </p>
          <v-select
            class="w-full"
            label="name"
            v-model="selectedService"
            :multiple="false"
            :closeOnSelect="true"
            :placeholder="$t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.ServiceSelectPlaceholder')"
            :options="appServices"
            :clearable="false">
          </v-select>
        </div>
        <div
          v-if="appServiceAvailableCountries.length > 0"
          class="vx-col w-full md:w-1/2 flex items-center mt-3 md:mt-0 md:pl-3">
          <p class="whitespace-no-wrap mr-3">
            {{ $t('$General.To') | lowercase }}
          </p>
          <v-select
            ref="vSelectCountries"
            class="w-full"
            label="name"
            v-model="selectedCountry"
            :multiple="false"
            :closeOnSelect="true"
            :placeholder="$t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.CountrySelectPlaceholder')"
            :options="appServiceAvailableCountries"
            :clearable="false">
          </v-select>
        </div>
      </div>

      <vs-divider/>

      <div class="mt-3">
        <small v-html="$t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.AboutCreditAndCountriesMsg', {
          url: 'https://www.google.co.ve/?gws_rd=cr&ei=jSVrUpetG83xkQf40YFg',
        })">
        </small>
      </div>
    </vx-card>

    <div class="vx-row">
      <div
          v-for="(appServicePackage, index) in appServicePackagesWithTotals"
          :key="index"
          class="vx-col w-full sm:w-1/2 lg:w-1/4 mb-base">
          <transition name="zoom-fade">
            <vx-card
              no-shadow
              card-border>
            <h5 class="text-center">
              {{ getQuantityFromPackage(appServicePackage) }} {{ selectedService.name | uppercase }}
            </h5>
            <p class="text-grey text-center">
              <small>
                {{ $t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.UnitPriceMsg', {
                price: $options.filters.dollar(getPriceFromPackage(appServicePackage), 3),
              }) }}
              </small>
            </p>

            <div class="flex justify-center items-center">
              <h1 class="font-bold text-center">
                {{ appServicePackage.price | dollar(0) }}
              </h1>
              <small
                v-show="selectedPayPeriod === $enums.StripeEnums.Checkout.Mode.SUBSCRIPTION"
                class="whitespace-pre">
                / {{ $t('$General.Month') | lowercase }}
              </small>
            </div>

            <vs-divider></vs-divider>

            <h3
              v-if="selectedPayPeriod === $enums.StripeEnums.Checkout.Mode.SUBSCRIPTION
            && userMonthlyPackageNumber === index"
              class="text-center text-primary font-bold py-2">
              {{ $t('PackageCurrentlyActiveMsg') }}
            </h3>
            <div
              v-else
              class="flex justify-center">
              <vs-button @click="packageCheckout(appServicePackage, index)">
                {{ $tc('$General.Purchase') }}
              </vs-button>
            </div>
          </vx-card>
          </transition>
      </div>
    </div>

    <vs-popup
      :title="checkoutModalTitle"
      :active.sync="showCheckout"
      v-modal-no-scroll-body>
      <transition name="zoom-fade">
        <template v-if="showCheckout">
          <app-checkout
            v-if="getTenantFlag('completedAccountDetails')"
            :product-name="checkoutAppServicePackageName"
            :product-price="checkoutAppServicePackagePrice"
            :gst="appSettingsGst"
            :recurrent="selectedPayPeriod === $enums.StripeEnums.Checkout.Mode.SUBSCRIPTION"
            :payment-methods="paymentMethods"
            :selected-payment-method.sync="selectedPaymentMethod"
            :payment-failed-msg="paymentFailedMsg"
            @payment-intent="purchasePackage"
            @input-payment-method="paymentFailedMsg=''"/>
          <div v-else>
            <user-account-settings-details on-modal>
              <template v-slot:title>
                <h4 class="text-center mb-5">
                  {{ $t('$Components.$PackageCheckoutModal.CompleteYourAccountDetailsToPay') }}
                </h4>
              </template>

              <template v-slot:buttons-wrapper="{ save }">
                <div class="flex items-center justify-end mt-12">
                  <vs-button
                    @click="save()">
                    {{ $t('$General.GoToPayment') }}
                  </vs-button>
                </div>
              </template>
            </user-account-settings-details>
          </div>
        </template>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vSelect from 'vue-select';

// Mixins
import purchasePackageMixin from '@/views/modules/_mixins/purchasePackage.mixin';
import selectAppServiceMixin from '@/views/modules/user/_mixins/selectAppService.mixin';

// Components
import AppCheckout from '@/views/modules/_components/app-checkout/AppCheckout.vue';
import UserAccountSettingsDetails from '@/views/modules/user/user-account/user-account-settings/UserAccountSettingsDetails.vue';

/**
 * Component for user purchase credit
 *
 * @module views/modules/user-account/UserAccountPurchasesTopUpAccount
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed {Object[]} appServicePackagesWithTotals - packages to show with all info
 * @vue-event {void} fetchData - fetch data to show
 * @vue-event {number} getPriceFromPackage -
 * return the price from one package based on selected pay period
 * @vue-event {number} getQuantityFromPackage -
 * return the quantity to send of a package based on selected pay period
 * @vue-event {void} packageCheckout - show the checkout for selected package
 */
export default {
  name: 'UserAccountPurchasesTopUpAccount',
  i18n: {
    messages: {
      en: {
        PricingBasedOnSending: 'Pricing based on sending',
        SelectPayPeriodTitle: 'Select a pay period type',
        PrepaidOptionTitle: 'Pay as you go (Prepaid)',
        MonthlyOptionTitle: 'Monthly plan ({discount}% cheaper than prepaid)',
        PackageCurrentlyActiveMsg: 'Currently active',
      },
    },
  },
  components: {
    vSelect,
    AppCheckout,
    UserAccountSettingsDetails,
  },
  mixins: [selectAppServiceMixin, purchasePackageMixin],
  computed: {
    ...mapGetters({
      userMonthlyPackageNumber: 'auth/userMonthlyPackageNumber',
      getTenantFlag: 'auth/getTenantFlag',
    }),
    appServicePackagesWithTotals() {
      return this.appServicePackages.length > 0 && this.appSettingsPackages.length > 0
      && this.appServicePackages.length === this.appSettingsPackages.length
        ? this.appServicePackages.map((appServicePackage, index) => ({
          ...appServicePackage,
          ...this.appSettingsPackages[index],
        }))
        : [];
    },
  },
  created() {
    if (this.$mq === this.$enums.mqBreakpoints.DESKTOP) {
      window.history.pushState({}, null, '/purchases/manage-top-ups/top-up-account');
    }

    this.fetchData();
  },
  watch: {
    showCheckout() {
      this.paymentFailedMsg = '';
    },
  },
  methods: {
    ...mapActions({
      fetchAllAppServices: 'appService/fetchAllAppServices',
    }),
    async fetchData() {
      await this.getAppSettings();
      await this.getAppServices();

      if (this.appServices.length > 0) {
        [this.selectedService] = this.appServices;
      }

      await this.getPaymentMethods();
      await this.fetchBalanceInformation();
    },
    getPriceFromPackage(appServicePackage) {
      return this.selectedPayPeriod === this.$enums.StripeEnums.Checkout.Mode.PAYMENT
        ? appServicePackage.prepaidPrice
        : appServicePackage.monthlyPrice;
    },
    getQuantityFromPackage(appServicePackage) {
      return Number(appServicePackage.price / this.getPriceFromPackage(appServicePackage))
        .toFixed(0);
    },
    async packageCheckout(appServicePackage, packageNumber) {
      if (packageNumber === this.userMonthlyPackageNumber
        && this.selectedPayPeriod === this.$enums.StripeEnums.Checkout.Mode.SUBSCRIPTION) {
        return;
      }

      this.checkoutAppServicePackage = appServicePackage;
      this.checkoutAppServicePackageNumber = packageNumber;
      this.showCheckout = true;
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 550px) {
  .top-up-service-select {
    flex-wrap: wrap;
  }
}
</style>
