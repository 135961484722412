import allCountries from '@/assets/utils/all-countries';
import appServiceService from '@/api/modules/app-service.service';
import appServiceCountryService from '@/api/modules/app-service-country.service';

/**
 * Mixin to select app services and country to view info about service
 *
 * @module views/modules/user-account/mixins/selectAppService
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object} [selectedService=null] - selected service to purchase based on
 * @vue-data {Object} [selectedCountry=null] - selected country to purchase based on
 * @vue-data {Object} appServiceCountry - app service country to show
 * @vue-data {Object[]} appServices - available app services
 * @vue-computed {Object[]} appServicePackages - packages from selected app service
 * @vue-computed {Object[]} appServiceAvailableCountriesIso2 -
 * available countries iso2 codes from selectedService
 * @vue-computed {Object[]} appServiceAvailableCountries - available countries from selectedService
 * @vue-event {void} getAppServices - get the app services
 * @vue-event {void} getAppServiceCountry - get one app service country
 */
export default {
  data() {
    return {
      selectedService: null,
      selectedCountry: null,
      appServiceCountry: null,
      appServices: [],
    };
  },
  computed: {
    appServicePackages() {
      if (this.selectedService) {
        if (this.selectedService.pricesBasedOnCountry) {
          return this.appServiceCountry ? this.appServiceCountry.packages : [];
        }

        return this.selectedService.packages || [];
      }

      return [];
    },
    appServiceAvailableCountriesIso2() {
      return this.selectedService ? this.selectedService.availableCountriesIso2 : [];
    },
    appServiceAvailableCountries() {
      return allCountries.filter(
        (country) => this.appServiceAvailableCountriesIso2.includes(country.iso2),
      );
    },
  },
  watch: {
    selectedService(val) {
      if (val) {
        if (this.appServiceAvailableCountries.length > 0) {
          const prevSelectedCountryIso2 = this.selectedCountry ? this.selectedCountry.iso2 : null;
          const auCountry = this.appServiceAvailableCountries.find((country) => country.iso2 === 'AU');
          this.selectedCountry = auCountry || this.appServiceAvailableCountries[0];

          if (prevSelectedCountryIso2 === this.selectedCountry.iso2
            && this.selectedService.pricesBasedOnCountry) {
            this.getAppServiceCountry();
          }
        } else {
          this.selectedCountry = null;
        }
      }
    },
    selectedCountry(val) {
      if (val) {
        if (this.selectedService.pricesBasedOnCountry) {
          this.getAppServiceCountry();
        }
      }
    },
  },
  methods: {
    async getAppServices() {
      const resp = await appServiceService.getAll({
        filters: {
          packageBased: {
            filterType: 'boolean',
            filter: 'yes',
          },
        },
      });
      this.appServices = resp.data.data ? resp.data.data : [];
    },
    async getAppServiceCountry() {
      const resp = await appServiceCountryService.getOneByServiceAndCountry({
        serviceId: this.selectedService.id,
        countryIso2: this.selectedCountry.iso2,
      });
      this.appServiceCountry = resp.data ? resp.data : {};
    },
  },
};
